// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rj d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rk d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rl d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rm d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rn d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rp d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rq d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rr t_rr";
export var heroExceptionNormal = "r_rs t_rs";
export var heroExceptionLarge = "r_rt t_rt";
export var Title1Small = "r_rv t_rv t_rD t_rF";
export var Title1Normal = "r_rw t_rw t_rD t_rG";
export var Title1Large = "r_rx t_rx t_rD t_rH";
export var BodySmall = "r_ry t_ry t_rD t_rX";
export var BodyNormal = "r_rz t_rz t_rD t_rY";
export var BodyLarge = "r_rB t_rB t_rD t_rZ";