// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qS d_fp d_bG d_dv";
export var alignLeft = "q_qD d_fp d_bG d_dv";
export var alignDiscCenter = "q_qT d_fq d_bD d_dw";
export var alignCenter = "q_bP d_fq d_bD d_dw";
export var alignDiscRight = "q_qV d_fr d_bH d_dx";
export var alignRight = "q_qF d_fr d_bH d_dx";
export var footerContainer = "q_qW d_dW d_bW";
export var footerContainerFull = "q_qX d_dT d_bW";
export var footerHeader = "q_kf d_kf";
export var footerTextWrapper = "q_qY d_w";
export var footerDisclaimerWrapper = "q_km d_km d_ck";
export var badgeWrapper = "q_qZ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "q_q0 d_bz d_bJ d_bN d_bL";
export var wide = "q_q1 d_cy";
export var right = "q_q2 d_bK";
export var line = "q_fk d_fl d_cv";
export var badgeDisclaimer = "q_q3 d_bC d_bP d_bJ";
export var badgeContainer = "q_q4 d_bz d_bH d_bP";
export var badge = "q_q5";
export var padding = "q_q6 d_c7";
export var end = "q_q7 d_bH";
export var linkWrapper = "q_q8 d_dB";
export var link = "q_mC d_dB";
export var colWrapper = "q_q9 d_cx";
export var consent = "q_f d_f d_bC d_bP";
export var disclaimer = "q_rb d_bz d_bJ";
export var media = "q_rc d_bx d_dy";
export var itemRight = "q_rd";
export var itemLeft = "q_rf";
export var itemCenter = "q_rg";
export var exceptionWeight = "q_rh t_r4";