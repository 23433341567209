// extracted by mini-css-extract-plugin
export var alignLeft = "y_qD d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_qF d_fr d_bH d_dx";
export var menuContainer = "y_tY d_dW";
export var menuContainerFull = "y_tZ d_dT";
export var menuMainHeader = "y_jC d_jC d_w d_c3";
export var menuComponentWrapper = "y_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "y_jH d_jH d_cy";
export var menuComponentText = "y_jD d_jD d_cn";
export var menuComponentTextDesign2 = "y_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "y_jJ d_jJ d_w d_Z";